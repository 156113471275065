import React from "react"
import styled, { css } from "styled-components"
import { buttonReset } from "../core/reset"
import { rem } from "../core/utils"

const StyledButton = styled.button.attrs({ type: "button" })`
  ${buttonReset};

  background-color: transparent;
  border: 1px solid currentColor;
  border-radius: ${rem(5)};
  color: #2d2d2d;
  cursor: pointer;
  margin: ${rem(4)};
  padding: ${rem(20)} ${rem(32)};

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &:focus {
    box-shadow: 0 0 0 4px rgba(255, 125, 48, 0.32);
    outline: 0;
  }

  ${({ primary }) =>
    primary &&
    css`
      background-color: #000000;
      color: #fff;

      &:hover {
        background-color: #292929;
      }
    `};
`

export default function Button({
  primary,
  onClick,
  title,
  className,
  style,
  children,
}) {
  return (
    <StyledButton
      primary={primary}
      onClick={onClick}
      title={title}
      className={className}
      style={style}
    >
      {children}
    </StyledButton>
  )
}
