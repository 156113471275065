import { css } from "styled-components";

export const buttonReset = css`
  appearance: none;
  background-color: transparent;
  border: none;
  font: inherit;
  line-height: 1em;
  padding: 1em;
`;
