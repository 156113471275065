const theme = {
  screens: {
    sm: 768,
    md: 1024,
    lg: 1440,

    custom: {
      mobile: 450,
    },
  },
};
export default theme