import React from "react";
import styled, { css } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import { buttonReset } from "./core/reset";
import { rem } from "./core/utils";
import theme from "./core/theme";
import LanguageContext from "./context/languageContext";
import CustomCheckbox from "./components/CustomCheckbox";
import CookiesInfo from "./components/CookiesInfo";
import Button from "./components/Button";
import Logo from "./components/Logo";
import sikdatnes from "./data";

const Fixed = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  overflow-y: auto;

  &::before {
    background-color: rgba(0, 0, 0, 0.2);
    content: "";
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`;

const StyledCookiesConsent = styled(motion.section)`
  background-color: #fff;
  color: #4e4e4e;
`;

const Container = styled.div`
  padding: ${rem(32)} ${rem(24)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: ${rem(1140)};
    padding: ${rem(40)} ${rem(30)};
  }
`;

const LogoRow = styled.div`
  margin-bottom: ${rem(24)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    display: none;
  }
`;

const ContentRow = styled.div`
  margin-bottom: ${rem(16)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin-bottom: ${rem(24)};
  }
`;

const Content = styled.div`
  h6 {
    color: #2d2d2d;
    font-size: ${rem(20)};
    margin-top: 0;
    margin-bottom: 0.8em;
  }

  p {
    color: #4e4e4e;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5;

    & + & {
      margin-top: 1em;
    }
  }

  a {
    color: #fa4616;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: ${rem(theme.screens.md)}) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  border-radius: ${rem(5)};
  width: 100%;

  > div {
    padding: ${rem(8)} 0;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    flex-direction: row;
    margin-right: ${rem(11)};

    > div {
      flex: 1 1 auto;
    }

    > button {
      flex: 0 0 auto;
    }
  }

  @media (min-width: ${rem(theme.screens.md)}) {
    flex: 1 1 auto;
  }
`;

const ButtonGroup = styled.div`
  margin-bottom: ${rem(12)};
  text-align: center;

  > button {
    margin: ${rem(4)} 0;
    width: 100%;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    min-width: ${rem(314)};
    width: auto;

    > button {
      margin: 0 ${rem(4)};
      width: auto;
    }
  }

  @media (min-width: ${rem(theme.screens.md)}) {
    flex: 0 0 auto;
    display: flex;

    > button {
      margin: 0 ${rem(11)};
      min-width: 0;
      white-space: nowrap;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const StyledToggle = styled.button.attrs({ type: "button" })`
  ${buttonReset};

  border: 1px solid #e8e8e8;
  border-radius: 0 0 ${rem(5)} ${rem(5)};
  color: #4e4e4e;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(18)} ${rem(32)};

  > svg {
    flex: 0 0 auto;
    display: block;
    margin-left: ${rem(8)};
  }

  &:hover {
    background-color: #ebebeb;
  }

  &:focus {
    /* box-shadow: 0 0 0 4px rgba(255, 125, 48, 0.32); */
    outline: 0;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    border-radius: 0 ${rem(5)} ${rem(5)} 0;
  }
`;

function Toggle({ render, onToggle }) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <StyledToggle
      onClick={() => {
        setIsOpen(!isOpen);
        onToggle && onToggle(!isOpen);
      }}
    >
      {render(isOpen)}
    </StyledToggle>
  );
}

const ContentInfoRow = styled(motion.div)`
  overflow-y: hidden;
`;

// FIXME: Mobile tabus jāpārveido par
// akardeona elementu labākai responsivitātei

const TabsSection = styled.section`
  padding-top: ${rem(24)};
`;

const TabsGroup = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${rem(540)}) {
    flex-direction: row;
  }
`;

const TabButton = styled.button.attrs({ type: "button" })`
  ${buttonReset};

  flex: 1;
  background-color: #f6f6f6;
  border: 1px solid #e8e8e8;
  color: #4e4e4e;
  cursor: pointer;

  &:first-child {
    border-radius: ${rem(5)} ${rem(5)} 0 0;
  }

  & + & {
    border-top-width: 0;
  }

  &:hover {
    background-color: #ebebeb;
  }

  &:focus {
    outline: 0;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #2d2d2d;
      color: #f6f6f6;
      pointer-events: none;
    `};

  @media (min-width: ${rem(540)}) {
    &:first-child {
      border-radius: ${rem(5)} 0 0 0;
    }

    &:last-child {
      border-radius: 0 ${rem(5)} 0 0;
    }

    & + & {
      border-left-width: 0;
    }
  }
`;

const TabsContent = styled.div`
  border: 1px solid #e8e8e8;
  border-top-width: 0;
  border-radius: 0 0 ${rem(5)} ${rem(5)};
  padding: ${rem(24)};
`;

function Tabs({ tabs }) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const activeTab = tabs[activeIndex];
  return (
    <TabsSection>
      <TabsGroup>
        {tabs.map((tab, i) => (
          <TabButton
            key={tab.id}
            isActive={i === activeIndex}
            onClick={() => {
              setActiveIndex(i);
            }}
          >
            {tab.label}
          </TabButton>
        ))}
      </TabsGroup>
      <TabsContent>{activeTab ? activeTab.content : null}</TabsContent>
    </TabsSection>
  );
}

const Badge = styled.button.attrs({ type: "button" })`
  ${buttonReset};

  position: fixed;
  right: ${rem(4)};
  bottom: ${rem(4)};
  z-index: 100;

  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: ${rem(14)};
  padding: ${rem(6)} ${rem(12)};

  &:focus {
    outline: 0;
  }
`;

const contentInfoVariants = {
  initial: {
    height: "0",
  },
  enter: {
    height: "auto",
    transition: { type: "spring", damping: 50, stiffness: 350 },
  },
  exit: {
    height: "0",
  },
};

const contentInfoWrapperVariants = {
  initial: {
    opacity: 0,
    y: 10,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", damping: 35, stiffness: 350, delay: 0.1 },
  },
  exit: {
    opacity: 0,
  },
};

const consentVariants = {
  initial: {
    opacity: 0,
    y: "-20%",
  },
  enter: ({ force }) => ({
    opacity: 1,
    y: "0%",
    transition: {
      delay: force ? 0 : 1.5,
      type: "spring",
      damping: 50,
      stiffness: 150,
    },
  }),
};

export default React.memo(function CookiesConsent() {
  const { language, tulkojums } = React.useContext(LanguageContext);

  const data = sikdatnes[language];
  const tabs = React.useMemo(
    () =>
      Object.keys(data.cookies).map((key) => {
        const { label, description, cookies } = data.cookies[key];
        return {
          id: key,
          label: `${label} (${cookies.length})`,
          content: <CookiesInfo description={description} cookies={cookies} />,
        };
      }),
    [data.cookies]
  );

  React.useState(() => {
    if (typeof document !== "undefined") {
      document.body.classList.add("scroll--disabled");
    }
  }, []);

  const [showInfo, setShowInfo] = React.useState(false);
  const contentInfoRowRef = React.useRef();

  const [functionalCookies] = React.useState(() => {
    const value = Cookies.get("lmt_functional_cookies");
    if (value) {
      try {
        new Date(value);
        return true;
      } catch (error) {}
    }
  });
  const [analyticalCookies, setAnalyticalCookies] = React.useState(() => {
    const value = Cookies.get("lmt_analytical_cookies");
    if (value) {
      try {
        new Date(value);
        return true;
      } catch (error) {}
    } else if (functionalCookies) {
      return false;
    }
  });
  const [marketingCookies, setMarketingCookies] = React.useState(() => {
    const value = Cookies.get("lmt_marketing_cookies");
    if (value) {
      try {
        new Date(value);
        return true;
      } catch (error) {}
    } else if (functionalCookies) {
      return false;
    }
  });

  const scripts =
    process.env.NODE_ENV === "production" ? (
      <>
        {analyticalCookies && (
          <Helmet>
            {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
            <script
              key="gtag-ua"
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-D67KT4SWTN"
            />
            <script key="gtag-ua-inline">{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-D67KT4SWTN');
          `}</script>
          </Helmet>
        )}

        {marketingCookies && (
          <>
            <Helmet>
              {/* <!-- Facebook Pixel Code --> */}
              <script key="facebook-pixel-inline">{`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '798647437658459');
              fbq('track', 'PageView');
              `}</script>
            </Helmet>

            <Helmet>
              {/* salespoints.co */}
              <script
                key="salespoints"
                async
                src="https://foxy.salespoints.co/?script=foxy_1038"
              />
            </Helmet>
          </>
        )}
      </>
    ) : (
      ""
    );

  const [forceShowConsent, setForceShowConsent] = React.useState(false);

  if (!forceShowConsent && functionalCookies) {
    if (typeof document !== "undefined") {
      document.body.classList.remove("scroll--disabled");
    }

    // FIXME: Vai pēc izmaiņām sīkdatņu iestatījumos
    // vajadzētu dzēst iepriekš izveidotās 3. pušu sīkdatnes?

    return (
      <>
        {scripts}
        <Badge
          focusIndex={-1}
          onClick={() => {
            setForceShowConsent(true);
          }}
        >
          {tulkojums("sikdatnu_iestatijumi")}
        </Badge>
      </>
    );
  } else {
    if (typeof document !== "undefined") {
      document.body.classList.add("scroll--disabled");
    }
  }

  return (
    <>
      <Fixed>
        <StyledCookiesConsent
          initial="initial"
          animate="enter"
          variants={consentVariants}
          custom={{ force: forceShowConsent }}
        >
          <Container>
            <LogoRow>
              <Logo />
            </LogoRow>

            <ContentRow>
              <Content dangerouslySetInnerHTML={{ __html: data.saturs.html }} />
            </ContentRow>

            <ActionRow>
              <FieldGroup>
                <div>
                  <CustomCheckbox
                    label={tulkojums("nepieciesamas")}
                    checked
                    disabled
                  />
                  {data.cookies.analytical && (
                    <CustomCheckbox
                      label={tulkojums("statistika")}
                      checked={
                        analyticalCookies !== undefined
                          ? analyticalCookies
                          : true
                      }
                      onChange={(event) => {
                        setAnalyticalCookies(event.target.checked);
                      }}
                    />
                  )}
                  {data.cookies.marketing && (
                    <CustomCheckbox
                      label={tulkojums("marketings")}
                      checked={
                        marketingCookies !== undefined
                          ? marketingCookies
                          : false
                      }
                      onChange={(event) => {
                        setMarketingCookies(event.target.checked);
                      }}
                    />
                  )}
                </div>
                <Toggle
                  onToggle={(isOpen) => {
                    setShowInfo(isOpen);
                  }}
                  render={(isOpen) => {
                    const label = isOpen
                      ? tulkojums("paslept")
                      : tulkojums("uzzinat_vairak");
                    const transform = isOpen ? "scaleY(-1)" : "scaleY(1)";
                    return (
                      <>
                        {label}
                        <svg
                          width="20"
                          height="20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ transform }}
                        >
                          <path
                            d="M5 7.5l5 5 5-5"
                            stroke="#4E4E4E"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </>
                    );
                  }}
                />
              </FieldGroup>
              <ButtonGroup>
                <Button
                  primary
                  onClick={() => {
                    const date = new Date();
                    const dateString = date.toLocaleDateString("lv");

                    Cookies.set("lmt_functional_cookies", dateString, {
                      expires: 365,
                    });
                    if (data.cookies.analytical) {
                      Cookies.set("lmt_analytical_cookies", dateString, {
                        expires: 365,
                      });
                    }
                    if (data.cookies.marketing) {
                      Cookies.set("lmt_marketing_cookies", dateString, {
                        expires: 365,
                      });
                    }

                    try {
                      window.location.reload();
                    } catch (error) {}
                  }}
                >
                  {tulkojums("apstiprinat_visas")}
                </Button>
                <Button
                  onClick={() => {
                    const date = new Date();
                    const dateString = date.toLocaleDateString("lv");

                    Cookies.set("lmt_functional_cookies", dateString, {
                      expires: 365,
                    });
                    if (data.cookies.analytical) {
                      if (
                        analyticalCookies ||
                        analyticalCookies ===
                          undefined /* Pēc noklusējuma aktīvs, bet nav apstiprināts */
                      ) {
                        Cookies.set("lmt_analytical_cookies", dateString, {
                          expires: 365,
                        });
                      } else {
                        Cookies.remove("lmt_analytical_cookies");
                      }
                    }
                    if (data.cookies.marketing) {
                      if (marketingCookies) {
                        Cookies.set("lmt_marketing_cookies", dateString, {
                          expires: 365,
                        });
                      } else {
                        Cookies.remove("lmt_marketing_cookies");
                      }
                    }

                    try {
                      window.location.reload();
                    } catch (error) {}
                  }}
                >
                  {tulkojums("apstiprinat_atzimetas")}
                </Button>
              </ButtonGroup>
            </ActionRow>

            <AnimatePresence exitBeforeEnter>
              {showInfo && (
                <ContentInfoRow
                  ref={contentInfoRowRef}
                  initial="initial"
                  animate="enter"
                  exit="exit"
                  variants={contentInfoVariants}
                  onAnimationStart={() => {
                    contentInfoRowRef.current.style.overflowY = "hidden";
                  }}
                >
                  <motion.div
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    variants={contentInfoWrapperVariants}
                    onAnimationComplete={() => {
                      contentInfoRowRef.current.style.overflowY = "auto";
                    }}
                  >
                    <Tabs tabs={tabs} />
                  </motion.div>
                </ContentInfoRow>
              )}
            </AnimatePresence>
          </Container>
        </StyledCookiesConsent>
      </Fixed>
    </>
  );
});
