import React from "react";

export const defaultLanguage = "lv";
export const languages = ["lv", "ru", "en"];

export function translate(data, lang, langs = languages) {
  const newData = {};
  Object.keys(data).forEach((key) => {
    const lastIndexOfUnderline = key.lastIndexOf("__");
    if (lastIndexOfUnderline > -1) {
      const suffix = key.substr(lastIndexOfUnderline + 2);
      if (langs.includes(suffix)) {
        if (lang === suffix) {
          const keyBase = key.substring(0, lastIndexOfUnderline);
          newData[keyBase] = data[key];
        } // else - key from alternate language
      } else {
        // different suffix
        newData[key] = data[key];
      }
    } else {
      // "__" not present
      newData[key] = data[key];
    }
  });
  return newData;
}

const LanguageContext = React.createContext({
  language: defaultLanguage,
  changeLanguage: () => {},
  tulkojumi: [],
  tulkojums: () => {},
});
export default LanguageContext;

export function LanguageProvider({ contextLang, children, tulkojumi = [] }) {
  const [language, setlanguage] = React.useState(
    () => contextLang || defaultLanguage
  );

  const tulkojumuDati = React.useMemo(
    () => tulkojumi.map((node) => translate(node, language)),
    [language, tulkojumi]
  );

  function changeLanguage(newLanguage) {
    if (languages.includes(newLanguage)) {
      setlanguage(newLanguage);
    } else {
      console.warn(`Language '${newLanguage}' is not intended`);
    }
  }

  function tulkojums(id) {
    const tulkojums = tulkojumuDati.find(({ uid }) => uid === id);
    if (!tulkojums) {
      console.warn(`Nav tulkojuma '${id}'`);
      return id;
    }
    return tulkojums.teksts;
  }

  return (
    <LanguageContext.Provider
      value={{
        contextLang,
        language,
        changeLanguage,
        tulkojums,
        tulkojumi: tulkojumuDati,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}
