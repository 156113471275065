import React from "react"
import styled from "styled-components"
import { rem } from "../core/utils"
import LanguageContext from "../context/languageContext"

const ContentInfo = styled.div``

const Text = styled.p`
  font-size: ${rem(14)};
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: 0;

  & + & {
    margin-top: 1.5em;
  }
`

const TableWrapper = styled.div`
  overflow-x: auto;
`

const Table = styled.table`
  border-collapse: collapse;
  font-size: ${rem(12)};
  margin-top: ${rem(24)};
  width: 100%;
  min-width: ${rem(640)};

  th,
  td {
    border-left: 1px solid #e8e8e8;
    padding: ${rem(8)} ${rem(12)};

    &:first-child {
      border-left: 0;
    }
  }

  th {
    background-color: #f6f6f6;
    color: #2d2d2d;
    font-weight: 400;
    text-align: left;

    & + & {
      border-left: 1px solid #e8e8e8;
    }
  }

  td {
    color: #4e4e4e;
  }

  tbody {
    tr {
      border-bottom: 1px solid #e8e8e8;
    }
  }
`

export default function CookiesInfo({ description, cookies }) {
  const { tulkojums } = React.useContext(LanguageContext)
  return (
    <ContentInfo>
      <Text>{description}</Text>
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th>{tulkojums("nosaukums")}</th>
              <th>{tulkojums("pakalpojuma_sniedzejs")}</th>
              <th>{tulkojums("ilgums")}</th>
              <th>{tulkojums("merkis")}</th>
            </tr>
          </thead>
          <tbody>
            {cookies.map(sikdatne => (
              <tr key={sikdatne.nosaukums}>
                {Object.keys(sikdatne).map(key => (
                  <td key={key}>{sikdatne[key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </ContentInfo>
  )
}
