const tulkojumi = [
  {
    uid: "sikdatnu_iestatijumi",
    teksts__lv: "Sīkdatņu iestatījumi",
    teksts__ru: "Настройки cookie",
    teksts__en: "Cookie settings",
  },
  {
    uid: "nepieciesamas",
    teksts__lv: "Nepieciešamās",
    teksts__ru: "Необходимое",
    teksts__en: "Required",
  },
  {
    uid: "statistika",
    teksts__lv: "Statistika",
    teksts__ru: "Статистика",
    teksts__en: "Statistics",
  },
  {
    uid: "marketings",
    teksts__lv: "Mārketings",
    teksts__ru: "Маркетинг",
    teksts__en: "Marketing",
  },
  {
    uid: "paslept",
    teksts__lv: "Paslēpt",
    teksts__ru: "Спрятать",
    teksts__en: "Hide",
  },
  {
    uid: "uzzinat_vairak",
    teksts__lv: "Uzzināt vairāk",
    teksts__ru: "Узнать больше",
    teksts__en: "Find out more",
  },
  {
    uid: "apstiprinat_visas",
    teksts__lv: "Apstiprināt visas",
    teksts__ru: "Подтвердить все",
    teksts__en: "Confirm all",
  },
  {
    uid: "apstiprinat_atzimetas",
    teksts__lv: "Apstiprināt atzīmētās",
    teksts__ru: "Подтвердить отмеченное",
    teksts__en: "Confirm checked",
  },
  {
    uid: "nosaukums",
    teksts__lv: "Nosaukums",
    teksts__ru: "Название",
    teksts__en: "Name",
  },
  {
    uid: "pakalpojuma_sniedzejs",
    teksts__lv: "Pakalpojumu sniedzējs",
    teksts__ru: "Поставщик услуг",
    teksts__en: "Service Provider",
  },
  {
    uid: "ilgums",
    teksts__lv: "Ilgums",
    teksts__ru: "Длительость",
    teksts__en: "Duration",
  },
  {
    uid: "merkis",
    teksts__lv: "Mērķis",
    teksts__ru: "Цель использования",
    teksts__en: "Purpose",
  },
];

export default tulkojumi;
