import React from "react";
import ReactDOM from "react-dom";
import CookiesConsentCell from "./components/CookiesConsent/CookiesConsentCell";

ReactDOM.render(
  <React.StrictMode>
    <CookiesConsentCell />
  </React.StrictMode>,
  document.getElementById("cookies-consent")
);
