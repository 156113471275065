import React from "react"
import styled from "styled-components"
import { em } from "../core/utils"

// TODO: https://www.figma.com/file/MOBREWcgWzLRgNLVFBZLKy/LMT-%2F-Design-System?node-id=465%3A46

const StyledCustomCheckbox = styled.label`
  display: inline-flex;
  align-items: center;
  margin: 0.5em 1em;
  position: relative;
`

// https://codepen.io/SaraSoueidan/pen/BajzpEr

const Input = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  width: ${em(26)};
  height: ${em(26)};
  opacity: 0;

  font-size: inherit;
  margin: 0;

  & + svg {
    margin-right: 0.5em;

    rect:nth-child(1),
    rect:nth-child(3),
    path {
      display: none;
    }
  }

  &,
  & ~ span {
    cursor: pointer;
  }

  &:focus + svg {
    rect:nth-child(1) {
      display: inline;
    }
  }

  &:checked + svg {
    rect:nth-child(3),
    path {
      display: inline;
    }
  }

  &:disabled {
    &,
    & ~ span {
      cursor: not-allowed;
    }

    & + svg {
      rect:nth-child(2) {
        stroke: #969696;
      }
      rect:nth-child(3) {
        fill: #969696;
      }
    }
  }
`

const Label = styled.span`
  user-select: none;
`

export default function CustomCheckbox({ checked, disabled, label, onChange }) {
  return (
    <StyledCustomCheckbox>
      <Input defaultChecked={checked} disabled={disabled} onChange={onChange} />
      <svg
        width="26"
        height="26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.5"
          y="1.5"
          width="23"
          height="23"
          rx="4.5"
          stroke="#36B83C"
          strokeOpacity=".32"
          strokeWidth="3"
        />
        <rect
          x="3.5"
          y="3.5"
          width="19"
          height="19"
          rx="2.5"
          stroke="#4E4E4E"
        />
        <rect x="6" y="6" width="14" height="14" rx="1" fill="#39BB3F" />
        <path
          d="M17.667 9.5l-6.417 6.417L8.333 13"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <Label>{label}</Label>
    </StyledCustomCheckbox>
  )
}
