import React from "react";
import { LanguageProvider } from "./context/languageContext";
import CookiesConsent from "./CookiesConsent";
import tulkojumi from "./tulkojumi";

export default function CookiesConsentCell() {
  const lang = document.documentElement.lang?.split("-")?.[0] ?? "lv";
  return (
    <LanguageProvider contextLang={lang} tulkojumi={tulkojumi}>
      <CookiesConsent />
    </LanguageProvider>
  );
}
