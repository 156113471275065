import React from "react"

export default function Logo() {
  return (
    <svg width="86" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.015 5.702H0V28h4.015V5.702zM7.249 11.286h3.682v1.538c.785-1.508 2.837-2.08 4.408-2.08 1.719 0 3.41.724 4.466 2.08.15.15.272.333.393.513.12-.212.27-.392.421-.513 1.177-1.417 3.078-2.08 4.891-2.08 1.963 0 3.774.572 5.1 2.08 1.54 1.66 1.72 3.622 1.72 5.793V28h-4.016v-8.902c0-.966-.03-2.354-.604-3.197-.513-.784-1.539-1.238-2.506-1.238-1.117 0-2.173.483-2.777 1.447-.693 1.088-.634 2.717-.634 3.922v7.966h-4.01v-8.9c0-1.087 0-2.113-.634-3.076-.575-.876-1.6-1.359-2.627-1.359-3.199 0-3.26 3.018-3.26 5.372V28H7.249V11.286zM35.316 5.702h4.016v5.584h3.472v3.56h-3.472V28h-4.016V5.702z"
        fill="#292529"
      />
      <path
        d="M77.08 21.02c-.607 0-1.187-.204-1.554-.549A863.828 863.828 0 0070.64 15.8c-.457-.43-.693-.989-.669-1.576.025-.591.314-1.139.817-1.543l6.72-4.854c.689-.466 1.397-.712 2.048-.712 1.097 0 2.01.71 2.271 1.765l1.717 7.139c.182.757-.247 1.76-.92 2.147l-4.501 2.601c-.284.165-.654.254-1.044.254zm8-5.37l-1.716-7.145c-.44-1.777-1.97-2.97-3.81-2.97-.975 0-1.994.342-2.948.993l-6.765 4.886-.023.017c-.878.695-1.386 1.662-1.43 2.722a3.634 3.634 0 001.169 2.8 864.114 864.114 0 014.876 4.665l.005.005c.663.623 1.628.98 2.641.98.674 0 1.308-.16 1.833-.465l4.501-2.601c1.283-.74 2.015-2.448 1.667-3.888z"
        fill="#B5002F"
      />
      <path
        d="M72.684 13.84c-.22 0-.427-.032-.619-.094l-4.062-1.319C67 12.102 66.12 10.894 66.12 9.84v-6.2c0-.661.173-1.2.496-1.566.288-.32.693-.49 1.176-.49.326 0 .678.076 1.046.224l7.046 2.847c.73.296 1.268.839 1.512 1.524.244.688.168 1.447-.212 2.137l-2.414 4.364c-.378.682-1.236 1.16-2.087 1.16zm6.206-8.192c-.398-1.12-1.254-1.995-2.413-2.463L69.433.338A4.396 4.396 0 0067.796 0c-1.917 0-3.256 1.496-3.256 3.639v6.2c0 1.752 1.307 3.552 2.974 4.092l4.065 1.319c.35.113.723.172 1.108.172 1.423 0 2.819-.796 3.472-1.98l2.412-4.363c.605-1.092.718-2.31.319-3.431z"
        fill="#E00027"
      />
      <path
        d="M58.522 12.637l11.037-4.11a2.03 2.03 0 01.684-.125c.754 0 1.304.488 1.47 1.301l.685 3.358.834 4.092c.052.259.03.483-.067.6-.089.108-.254.13-.377.13a1.27 1.27 0 01-.262-.03l-13.38-2.778-.007-.003-.008-.002c-1.013-.195-1.672-.658-1.716-1.206-.037-.47.407-.966 1.107-1.227zm16.259 4.2l-1.52-7.45c-.313-1.535-1.526-2.568-3.021-2.568-.404 0-.814.074-1.223.22l-11.05 4.114c-1.393.52-2.23 1.637-2.128 2.845.052.628.355 1.205.873 1.666.525.466 1.236.789 2.116.961l13.375 2.779c.198.042.395.061.582.061.659 0 1.229-.251 1.603-.712.407-.493.546-1.174.393-1.916z"
        fill="#EC6D1F"
      />
      <path
        d="M50.14 16.233c-.768.21-1.276.254-1.508-.024-.163-.197-.138-.611.26-1.44l4.503-9.348c.34-.703.819-1.107 1.312-1.107.422 0 .873.281 1.275.791 1.327 1.69 4.977 6.304 5.012 6.348.32.41.439.816.333 1.152-.106.332-.437.596-.933.744L50.14 16.233zm12.097-5.763c-.037-.047-3.685-4.66-5.01-6.346-.707-.9-1.6-1.395-2.518-1.395-1.127 0-2.126.73-2.74 2.004l-4.503 9.348c-.733 1.519-.499 2.453-.175 2.968.372.595 1.055.92 1.918.92.417 0 .878-.074 1.372-.22l10.245-2.884.007-.002c1.002-.296 1.732-.947 1.998-1.782.27-.836.052-1.788-.594-2.611z"
        fill="#F08400"
      />
    </svg>
  )
}
