const lv = {
  saturs: {
    html:
      '<h6>Šī tīmekļa vietne izmanto sīkdatnes</h6><p>Mēs izmantojam sīkdatnes, lai nodrošinātu Tev patīkamu tīmekļa vietnes apmeklējuma pieredzi. Sīkdatnes Tev ļauj droši piekļūt lapai, bet mums – analizēt lapas apmeklējumu un uzlabot tās darbību. Šeit Tu vari izvēlēties, kuras sīkdatnes apstiprināt. Uzzini vairāk <a href="http://lmt.mstatic.lv/files/cookies/sikdatnes_noteikumi_lv_28_05_2020.pdf" target="_blank">LMT sīkdatņu izmantošanas noteikumos</a> un <a href="https://static.lmt.lv/lmt/files/pdf/lmt-privatuma-politika_26_03_2018.pdf" target="_blank">Privātuma politikā</a>. Atceries, ka savu izvēli jebkurā laikā vari mainīt, nospiežot uz <b>Sīkdatņu iestatījumi</b>.</p>',
  },
  cookies: {
    functional: {
      label: "Nepieciešamās",
      description:
        "Nepieciešamās sīkdatnes palīdz nodrošināt tīmekļa vietnes pamatfunkcijas, piemēram, drošība, pārvaldība un pieejamība. Tīmekļa vietne bez šiem sīkfailiem nevar pilnvērtīgi funkcionēt. Bez zemāk uzskaitītajām nepieciešamajām sīkdatnēm vietnē var tikt izmantotas arī citas līdzīgas nozīmes funkcionālai darbībai nepieciešamas sīkdatnes.",
      cookies: [
        {
          nosaukums: "lmt_functional_cookies",
          pakalpojumu_sniedzejs: "LMT.lv",
          uzglabasanas_ilgums: "1 gads",
          apraksts:
            "Reģistrē datumu, kad apmeklētājs ir apstiprinājis funkcionālo sīkdatņu izmantošanu.",
        },
        {
          nosaukums: "lmt_analytical_cookies",
          pakalpojumu_sniedzejs: "LMT.lv",
          uzglabasanas_ilgums: "1 gads",
          apraksts:
            "Reģistrē datumu, kad apmeklētājs ir apstiprinājis analītisko sīkdatņu izmantošanu.",
        },
        {
          nosaukums: "lmt_marketing_cookies",
          pakalpojumu_sniedzejs: "LMT.lv",
          uzglabasanas_ilgums: "1 gads",
          apraksts:
            "Reģistrē datumu, kad apmeklētājs ir apstiprinājis mārketinga sīkdatņu izmantošanu.",
        },
      ],
    },
    analytical: {
      label: "Statistika",
      description:
        "Statistikas sīkdatnes sniedz iespēju izprast, kā apmeklētājs mijiedarbojas ar tīmekļa vietnēm. Iegūtā informācija ir pieejama apkopotā veidā, tieši neidentificējot apmeklētāju.",
      cookies: [
        {
          nosaukums: "_ga",
          pakalpojumu_sniedzejs: "google.com",
          uzglabasanas_ilgums: "2 gadi",
          apraksts:
            "Google Analytics sīkdatne, kas ļauj veikt unikālo apmeklētāju uzskaiti.",
        },
        {
          nosaukums: "_gid",
          pakalpojumu_sniedzejs: "google.com",
          uzglabasanas_ilgums: "24 stundas",
          apraksts:
            "Google Analytics sīkdatne, kas ļauj noskaidrot apmeklētāja uzvedību tīmekļa vietnē.",
        },
        {
          nosaukums: "_gat",
          pakalpojumu_sniedzejs: "google.com",
          uzglabasanas_ilgums: "1 minūte",
          apraksts:
            "Google Analytics sīkdatne, kas ierobežo pieprasījumu apjomu.",
        },
      ],
    },
    marketing: {
      label: "Mārketings",
      description:
        "Mārketinga sīkdatnes sniedz iespēju mērīt LMT mārketinga un informatīvo kampaņu efektivitāti un saprast, kādā ceļā apmeklētājs no reklāmas nonāk LMT vietnē. Tāpat šīs sīkdatnes palīdz nodrošināt apmeklētājam pielāgotu informāciju.",
      cookies: [
        {
          nosaukums: "fr",
          pakalpojumu_sniedzejs: "facebook.com",
          uzglabasanas_ilgums: "3 mēneši",
          apraksts:
            "Sīkdatne, kas palīdz sasniegt Facebook lietotāju ar tam saistošu reklāmu.",
        },
        {
          nosaukums: "tr",
          pakalpojumu_sniedzejs: "facebook.com",
          uzglabasanas_ilgums: "Sesija",
          apraksts:
            "Sīkdatne, kas palīdz apmeklētājam piedāvāt saistošu reklāmu vietnē Facebook.",
        },
        {
          nosaukums: "_fbp",
          pakalpojumu_sniedzejs: "facebook.com",
          uzglabasanas_ilgums: "Sesija",
          apraksts:
            "Sīkdatne ļauj mērīt reklāmas efektivitāti, kā arī nodrošina reklāmas pielāgošanu dažādām mērķa grupām.",
        },
        {
          nosaukums: "foxy_tracking",
          pakalpojumu_sniedzejs: "sendigo.com",
          uzglabasanas_ilgums: "6 mēneši",
          apraksts:
            "Sīkdatne tiek izmantota, lai atpazītu jaunu vietnes apmeklētāju, kas ļautu pielāgot mārketinga e-pastu sūtīšanu.",
        },
        {
          nosaukums: "foxyId",
          pakalpojumu_sniedzejs: "sendigo.com",
          uzglabasanas_ilgums: "6 mēneši",
          apraksts:
            "Sīkdatne tiek izvietota, lai pēc klikšķa uz saites e-pastā atpazītu jaunu vietnes apmeklētāju un pielāgotu personalizētu jaunumu sūtīšanu.",
        },
        {
          nosaukums: "sendigo_foxyId",
          pakalpojumu_sniedzejs: "sendigo.com",
          uzglabasanas_ilgums: "6 mēneši",
          apraksts:
            "Izveido un saglabā foxy_tracking un foxyId sīkdatņu dublikātus, lai varētu nolasīt datus no pārlūka Local Storage.",
        },
        {
          nosaukums: "sendigo_push",
          pakalpojumu_sniedzejs: "sendigo.com",
          uzglabasanas_ilgums: "6 mēneši",
          apraksts:
            "Sīkdatne tiek piešķirta vietnes apmeklētājam, kas piekrīt iznirstošo ziņojumu saņemšanai.",
        },
      ],
    },
  },
};

const ru = {
  saturs: {
    html:
      '<h6>Этот сайт использует файлы cookie</h6><p>Мы используем файлы cookie, чтобы обеспечить тебе приятный опыт пользования сайтом. Файлы cookie позволяют тебе получить безопасный доступ к сайту, а нам позволяют анализировать посещаемость сайта и повышать его эффективность. Здесь ты можешь выбрать, какие файлы cookie принимать. Узнай больше в <a href="https://lmt.mstatic.lv/files/cookies/sikdatnes_noteikumi_ru_28_05_2020.pdf" target="_blank">Политике использования файлов cookie LMT</a> и <a href="https://static.lmt.lv/lmt/files/pdf/lmt-privatuma-politika_26_03_2018.pdf" target="_blank">Политике частности</a>. Помни, что ты можешь изменить свои настройки в любое время, активировав ссылку <b>Настройки файлов cookie</b>.</p>',
  },
  cookies: {
    functional: {
      label: "Необходимое",
      description:
        "Необходимые файлы cookie помогают обеспечить основные функции веб-сайта, такие как безопасность, управляемость и доступность. Без этих файлов cookie веб-сайт не может функционировать должным образом. В дополнение к обязательным файлам cookie, перечисленным ниже, веб-сайт может также использовать другие файлы cookie, необходимые для аналогичных функциональных целей.",
      cookies: [
        {
          nosaukums: "lmt_functional_cookies",
          pakalpojumu_sniedzejs: "LMT.lv",
          uzglabasanas_ilgums: "1 год",
          apraksts:
            "Записывает дату, когда посетитель подтвердил использование функциональных файлов cookie.",
        },
        {
          nosaukums: "lmt_analytical_cookies",
          pakalpojumu_sniedzejs: "LMT.lv",
          uzglabasanas_ilgums: "1 год",
          apraksts:
            "Записывает дату, когда посетитель подтвердил использование аналитических файлов cookie.",
        },
        {
          nosaukums: "lmt_marketing_cookies",
          pakalpojumu_sniedzejs: "LMT.lv",
          uzglabasanas_ilgums: "1 год",
          apraksts:
            "Записывает дату, когда посетитель одобрил использование маркетинговых файлов cookie.",
        },
      ],
    },
    analytical: {
      label: "Статистика",
      description:
        "Статистические файлы cookie позволяют понять, как посетитель взаимодействует с веб-сайтами. Полученная информация доступна в обработанном виде без прямой идентификации посетителя.",
      cookies: [
        {
          nosaukums: "_ga",
          pakalpojumu_sniedzejs: "google.com",
          uzglabasanas_ilgums: "2 года",
          apraksts:
            "Файл cookie Google Analytics, который позволяет отслеживать уникальных посетителей.",
        },
        {
          nosaukums: "_gid",
          pakalpojumu_sniedzejs: "google.com",
          uzglabasanas_ilgums: "24 ч",
          apraksts:
            "Файл cookie Google Analytics, который позволяет нам определить поведение посетителей на веб-сайте.",
        },
        {
          nosaukums: "_gat",
          pakalpojumu_sniedzejs: "google.com",
          uzglabasanas_ilgums: "1 мин",
          apraksts:
            "Файл cookie Google Analytics, ограничивающий объем запросов.",
        },
      ],
    },
    marketing: {
      label: "Маркетинг",
      description:
        "Маркетинговые файлы cookie дают возможность измерить эффективность маркетинговых и информационных кампаний LMT и понять, каким образом посетитель попадает на сайт LMT с помощью рекламы. Эти cookie-файлы также помогают предоставлять посетителям персонализированную информацию.",
      cookies: [
        {
          nosaukums: "fr",
          pakalpojumu_sniedzejs: "facebook.com",
          uzglabasanas_ilgums: "3 месяца",
          apraksts:
            "Файл cookie, который помогает достичь целевую группу пользователей Facebook связанной с ней рекламой.",
        },
        {
          nosaukums: "tr",
          pakalpojumu_sniedzejs: "facebook.com",
          uzglabasanas_ilgums: "Сессия",
          apraksts:
            "Файл cookie, который помогает посетителям предлагать привлекательную рекламу н Facebook.",
        },
        {
          nosaukums: "_fbp",
          pakalpojumu_sniedzejs: "facebook.com",
          uzglabasanas_ilgums: "Сессия",
          apraksts:
            "Файл cookie позволяет измерять эффективность рекламы, а также адаптировать ее к различным целевым группам.",
        },
        {
          nosaukums: "foxy_tracking",
          pakalpojumu_sniedzejs: "sendigo.com",
          uzglabasanas_ilgums: "6 месяцев",
          apraksts:
            "Файл cookie используется для идентификации нового посетителя веб-сайта, что позволяет настроить отправку маркетинговых электронных писем.",
        },
        {
          nosaukums: "foxyId",
          pakalpojumu_sniedzejs: "sendigo.com",
          uzglabasanas_ilgums: "6 месяцев",
          apraksts:
            "Файл cookie используется для распознавания нового посетителя сайта после нажатия на ссылку в электронном письме и для настройки отправки персонализированных новостей.",
        },
        {
          nosaukums: "sendigo_foxyId",
          pakalpojumu_sniedzejs: "sendigo.com",
          uzglabasanas_ilgums: "6 месяцев",
          apraksts:
            "Создает и сохраняет дубликаты файлов cookie foxy_tracking и foxyId, чтобы можно было считывать данные из браузера Local Storage.",
        },
        {
          nosaukums: "sendigo_push",
          pakalpojumu_sniedzejs: "sendigo.com",
          uzglabasanas_ilgums: "6 месяцев",
          apraksts:
            "Файл cookie присваивается посетителю веб-сайта, который соглашается получать всплывающие сообщения.",
        },
      ],
    },
  },
};

const en = {
  saturs: {
    html:
      '<h6>This website uses cookies</h6><p>We use cookies to ensure that you have the best experience on the website. Cookies allow you to securely access the site, but allow us to analyze the visit to the site and improve its performance. Here you can choose which cookies to accept. Learn more in the <a href="http://lmt.mstatic.lv/files/cookies/sikdatnes_noteikumi_lv_28_05_2020.pdf" target="_blank">LMT cookie usage policy</a> and <a href="https://static.lmt.lv/lmt/files/pdf/lmt-privacy-policy_26_03_2018.pdf" target="_ blank">Privacy Policy</a>. Remember that you can change your preferences at any time by clicking on <b>Cookie Settings</b>.</p>',
  },
  cookies: {
    functional: {
      label: "Required",
      description:
        "Required cookies help ensure the basic functions of the website, such as security, management and availability. The website cannot function properly without these cookies. In addition to the required cookies listed below, the website may use other cookies required for similar functional purposes.",
      cookies: [
        {
          nosaukums: "lmt_functional_cookies",
          pakalpojumu_sniedzejs: "LMT.lv",
          uzglabasanas_ilgums: "1 year",
          apraksts:
            "Record the date on which the visitor has confirmed the use of functional cookies.",
        },
        {
          nosaukums: "lmt_analytical_cookies",
          pakalpojumu_sniedzejs: "LMT.lv",
          uzglabasanas_ilgums: "1 year",
          apraksts:
            "Record the date on which the visitor has confirmed the use of analytical cookies.",
        },
        {
          nosaukums: "lmt_marketing_cookies",
          pakalpojumu_sniedzejs: "LMT.lv",
          uzglabasanas_ilgums: "1 year",
          apraksts:
            "Record the date the visitor approved the use of marketing cookies.",
        },
      ],
    },
    analytical: {
      label: "Statistics",
      description:
        "Statistical cookies make it possible to understand how a visitor interacts with websites. The information obtained is available in an aggregated form without directly identifying the visitor.",
      cookies: [
        {
          nosaukums: "_ga",
          pakalpojumu_sniedzejs: "google.com",
          uzglabasanas_ilgums: "2 years",
          apraksts:
            "Google Analytics cookie that allows unique visitor tracking.",
        },
        {
          nosaukums: "_gid",
          pakalpojumu_sniedzejs: "google.com",
          uzglabasanas_ilgums: "24 hours",
          apraksts:
            "Google Analytics cookie that allows us to track visitor behavior on a website.",
        },
        {
          nosaukums: "_gat",
          pakalpojumu_sniedzejs: "google.com",
          uzglabasanas_ilgums: "1 minute",
          apraksts:
            "Google Analytics cookie that limits the volume of requests.",
        },
      ],
    },
    marketing: {
      label: "Marketing",
      description:
        "Marketing cookies provide an opportunity to measure the effectiveness of LMT's marketing and information campaigns and to understand how a visitor arrives from an advertisement on the LMT website. These cookies also help to provide the visitor with personalized information.",
      cookies: [
        {
          nosaukums: "fr",
          pakalpojumu_sniedzejs: "facebook.com",
          uzglabasanas_ilgums: "3 months",
          apraksts:
            "A cookie that helps reach a Facebook user with a compelling ad.",
        },
        {
          nosaukums: "tr",
          pakalpojumu_sniedzejs: "facebook.com",
          uzglabasanas_ilgums: "Session",
          apraksts:
            "A cookie that helps a visitor to offer compelling advertising on Facebook.",
        },
        {
          nosaukums: "_fbp",
          pakalpojumu_sniedzejs: "facebook.com",
          uzglabasanas_ilgums: "Session",
          apraksts:
            "A cookie allows you to measure the effectiveness of your ad, as well as to tailor your ad to different audiences.",
        },
        {
          nosaukums: "foxy_tracking",
          pakalpojumu_sniedzejs: "sendigo.com",
          uzglabasanas_ilgums: "6 months",
          apraksts:
            "A cookie is used to identify a new visitor to a website that allows you to customize the sending of marketing emails.",
        },
        {
          nosaukums: "foxyId",
          pakalpojumu_sniedzejs: "sendigo.com",
          uzglabasanas_ilgums: "6 months",
          apraksts:
            "A cookie is placed to identify a new visitor to the site after clicking on the link in the email and to customize the sending of personalized news.",
        },
        {
          nosaukums: "sendigo_foxyId",
          pakalpojumu_sniedzejs: "sendigo.com",
          uzglabasanas_ilgums: "6 months",
          apraksts:
            "Creates and saves duplicates of foxy_tracking and foxyId cookies to be able to read data from browser Local Storage.",
        },
        {
          nosaukums: "sendigo_push",
          pakalpojumu_sniedzejs: "sendigo.com",
          uzglabasanas_ilgums: "6 months",
          apraksts:
            "A cookie is given to a visitor to the site who agrees to receive pop-ups.",
        },
      ],
    },
  },
};

const data = {
  lv,
  ru,
  en,
};

export default data;
